import { HTTP } from '../../axios/axios'
let data

async function getAll (lang, page) {
  try {
    const res = await HTTP.get('/carousel/pages/' + page, { headers: { 'accept-language': lang } })
    data = res.data
    return data.carouselSlides
  } catch (error) {
    console.log(error)
  }
}
async function addOne (slide, lang) {
  return await HTTP.patch('/carousel/' + data._id, { carouselSlides: [...data.carouselSlides, slide] })
}
async function update (slide, id) {
  return await HTTP.patch('/carousel/' + data._id, data)
}
async function deleteOne (slide) {
  const carouselSlides = data.carouselSlides.filter(i => i._id !== slide._id)
  return await HTTP.patch('/carousel/' + data._id, { carouselSlides })
}
async function deleteMany (ids) {
  const carouselSlides = data.carouselSlides.filter(i => ids.indexOf(i._id) === -1)
  return await HTTP.patch('/carousel/' + data._id, { carouselSlides })
}

async function changeOrder (carouselSlides) {
  return await HTTP.patch('/carousel/' + data._id, { carouselSlides })
}

export default { getAll, deleteMany, addOne, update, deleteOne, changeOrder }
